@import '../../../common/styles/sass/variables';

.title {
  padding-top: 60px;

  .subTitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    background: $colorGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    font-weight: 600;
    line-height: 62px;
    margin-bottom: 20px;
    color: $textColor;
  }

  & p {
    font-size: 18px;
    line-height: 30px;
    color: $secondaryColor;
  }
}





