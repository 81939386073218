@import './variables';

@mixin text($fontSize,$lineHeight) {
  font-family: $fontFamily;
  color: $textColor;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin container() {
  width: 80%;
  margin: 0 auto;
}

@mixin flex($direction, $justifyContent, $alignItems, $wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;

}

@mixin button() {
  text-decoration: none;
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  border: 2px solid $secondaryColor;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  letter-spacing: 0.2px;
}

@mixin bgSettings() {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}