@import '../common/styles/sass/variables';
@import '../common/styles/sass/mixins';

.contactsBlock {
  background-color: $bgSecondColor;
  flex-direction: column;
  font-family: $fontFamily;

  .contactsContainer {
    @include container();
    @include flex(column, space-between, center, wrap);

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 2px solid $borderColor;
      -webkit-text-fill-color: $secondaryColor;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%);
    }

  }

  .email {
    color: $secondaryColor;
    font-size: 18px;

    .linkEmail {
      color: $textColor;
      text-decoration: none;
      position: relative;

      &:hover {
        &::before {
          background: $colorGradient;
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 245px;
          height: 1px;
        }
      }
    }
  }

  .sentMessage {
    color: $textColor;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 3px solid $borderColor;
  }

  .form {
    width: 280px;
    display: flex;
    flex-direction: column;

    .input {
      border-radius: 5px;
      padding: 0 20px;
      border: 2px solid $borderColor;
      margin-bottom: 20px;
      outline: none;
      transition: all 0.3s ease;
      height: 50px;
      background: transparent;
      @include text(15px, 28px);
      color: $secondaryColor;
    }

    .textarea {
      border-radius: 5px;
      padding: 0 20px;
      border: 2px solid $borderColor;
      margin-bottom: 20px;
      outline: none;
      transition: all 0.3s ease;
      height: 150px;
      background: transparent;
      @include text(15px, 28px);
      color: $secondaryColor;
    }
  }

  .button {
    @include text(16px, 38px);
    text-decoration: none;
    padding: 0 23px;
    height: 40px;
    display: inline-block;
    background-color: $primaryColor;
    border-radius: 4px;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    letter-spacing: 0.2px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      border: 2px solid $primaryColor;
      background-color: rgba(28, 153, 254, 0);
      color: $primaryColor;
    }

    &:disabled {
      background-color: $secondaryColor;
      cursor: default;
      border: none;
      transition: none;
      color: $textColor
    }
  }
}





