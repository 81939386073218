@import '../../common/styles/sass/variables';

.educationContainer {
  display: flex;

  & ul {
    list-style: none;

    & li {
      padding-top: 20px;

      .title {
        font-weight: 500;
        color: $textColor;

        &:before {
          content: '\25A0';
          color: $primaryColor;
          padding-right: 5px;
          margin-bottom: 5px;
        }
      }

      .subTitle {
        color: $textColor;
        font-weight: 100;
        font-size: small;

        & div {
          color: $secondaryColor;
          font-weight: 500;
        }
      }
    }
  }


}
