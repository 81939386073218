@import '../../common/styles/sass/variables';

.workExperienceContainer {
  //display: flex;

  & ul {
    list-style: none;

    & h4 {
      font-size: 18px;
      line-height: 30px;
      color: $textColor;
      font-weight: 500
    }

    & li {
      margin-left: 20px;

      & img {
        padding-left: 5px;
        width: 20px;
        height: 20px;
      }

      .title {
        font-weight: 500;
        color: $textColor;
        text-decoration: none;

        &:before {
          content: '\25A0';
          color: $primaryColor;
          padding-right: 5px;
          margin-bottom: 5px;
        }
      }

      .subTitle {
        color: $textColor;
        font-weight: 100;
        font-size: small;

        & div {
          color: $secondaryColor;
          font-weight: 500;
          margin-bottom: 20px;
        }
        & p {
          color: $textColor;
          font-weight: 100;
          font-size: 13px;
          margin: 0;
        }
      }
    }
  }


}
