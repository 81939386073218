@import '../../common/styles/sass/variables';
@import '../../common/styles/sass/mixins';

.burgerNav {
  width: 100%;
  height: 100%;
  font-family: $fontFamily;

  .burgerNavItems {
    @include flex(column, space-between, center, nowrap);
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 0;
    background-color: $bgColor;
    padding: 10px;
    transform: translate(0, -180px);
    transition: 0.5s;

    a {
      padding: 10px 15px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      letter-spacing: 0.2px;
      border-radius: 4px;
      position: relative;
      color: $textColor;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: $primaryColor;
        transition: 0.3s;
      }
    }

    .active {
      background-color: $primaryColor;
      transition: 0.3s;
    }
  }

  .burgerButton {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #FD4766;
    position: absolute;
    top: 28px;
    right: 20px;
  }

  .show {
    transform: translate(0, 0);
    height: 180px;
  }

  @media screen and (min-width: 576px) {
    display: none;
  }

}


